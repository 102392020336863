import axios from 'src/utils/axios';

class JobsInDangerService {

  // Fetch initiated certificates created more than 30 days ago
  getInitiatedMoreThan30Days = (limit, page, analystId) => new Promise((resolve, reject) => {
    const addAnalystFilter = analystId ? `&analystId=${analystId}` : ""
    axios.get(`/jobsInDanger/initiated-more-than-30-days/?limit=${limit}&page=${page + 1}${addAnalystFilter}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Fetch initiated certificates created more than 30 days ago, and job created more than 120 days ago
  getInitiatedAndJobCreated = (limit, page, analystId) => new Promise((resolve, reject) => {
    const addAnalystFilter = analystId ? `&analystId=${analystId}` : ""
    axios.get(`/jobsInDanger/initiated-and-job-created?limit=${limit}&page=${page + 1}${addAnalystFilter}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Fetch application submitted certificates created more than 14 days ago
  getApplicationSubmittedMoreThan14Days = (limit, page, analystId) => new Promise((resolve, reject) => {
    const addAnalystFilter = analystId ? `&analystId=${analystId}` : ""
    axios.get(`/jobsInDanger/application-submitted-more-than-14-days?limit=${limit}&page=${page + 1}${addAnalystFilter}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Fetch application submitted certificates created more than 14 days ago, and job created more than 120 days ago
  getApplicationSubmittedAndJobCreated = (limit, page, analystId) => new Promise((resolve, reject) => {
    const addAnalystFilter = analystId ? `&analystId=${analystId}` : ""
    axios.get(`/jobsInDanger/application-submitted-and-job-created?limit=${limit}&page=${page + 1}${addAnalystFilter}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  getNotGrantedSince120days = (limit, page, analystId) => new Promise((resolve, reject) => {
    const addAnalystFilter = analystId ? `&analystId=${analystId}` : ""
    axios.get(`/jobsInDanger/not-granted-since-120-days?limit=${limit}&page=${page + 1}${addAnalystFilter}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  getAllTasksForAJob = (applicantID, jobID, sortOrder = 'desc') => new Promise((resolve, reject) => {
    axios.get(`/jobsInDanger/getTasks/${applicantID}/${jobID}?sortOrder=${sortOrder}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  getAllNotesForAJob = (applicantID, jobID, sortOrder = 'desc') => new Promise((resolve, reject) => {
    axios.get(`/jobsInDanger/getNotes/${applicantID}/${jobID}?sortOrder=${sortOrder}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  addReason = (values) => new Promise((resolve, reject) => {
    axios.post(`/jobsInDanger/reason`, values)
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  getReasons = (certificateId, sortOrder = 'desc') => new Promise((resolve, reject) => {
    axios.get(`/jobsInDanger/reason/${certificateId}?sortOrder=${sortOrder}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });


}

const jobsInDangerService = new JobsInDangerService();

export default jobsInDangerService;
